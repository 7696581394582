/* You can add global styles to this file, and also import other style files */
/* @import '@angular/material/prebuilt-themes/deeppurple-amber.css';*/

@font-face {
  font-family: 'Volvo Novum';
  src: url('./assets/fonts/volvo-novum/latin/Light.woff2');
  font-display: swap;
  font-weight: 200;
}

@font-face {
  font-family: 'Volvo Novum';
  src: url('/assets/fonts/volvo-novum/latin/LightItalic.woff2');
  font-display: swap;
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Volvo Novum';
  src: url('/assets/fonts/volvo-novum/latin/SemiLightItalic.woff2');
  font-display: swap;
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Volvo Novum';
  src: url('/assets/fonts/volvo-novum/latin/SemiLight.woff2');
  font-display: swap;
  font-weight: 300;
}

@font-face {
  font-family: 'Volvo Novum';
  src: url('/assets/fonts/volvo-novum/latin/Regular.woff2');
  font-display: swap;
  font-weight: 400;
}

@font-face {
  font-family: 'Volvo Novum';
  src: url('/assets/fonts/volvo-novum/latin/Italic.woff2');
  font-display: swap;
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Volvo Novum';
  src: url('/assets/fonts/volvo-novum/latin/Medium.woff2');
  font-display: swap;
  font-weight: 500;
}

@font-face {
  font-family: 'Volvo Novum';
  src: url('/assets/fonts/volvo-novum/latin/MediumItalic.woff2');
  font-display: swap;
  font-weight: 500;
  font-style: italic;
}

body {
  display: flow-root;
  margin: 0;
  letter-spacing: 0.3px;
  line-height: 1.6;
  background-color: var(---background);
  overflow-x: hidden;
  overflow-y: auto;
  font-family: 'Volvo Novum SemiLight' !important;
}

:root {
  --box-shadow-volvo: 0 0.1875rem 0.4375rem 0 rgba(0, 0, 0, 0.13), 0 0.0625rem 0.125rem 0 rgba(0, 0, 0, 0.11);
}

.regular-theme {
  ---background: #f7f7f7;
  ---color: #fafafa;
  ---text: #5e6e82;
  ---brand-title: #464854;
  ---button: rgb(18, 81, 181);
}

.field-header {
  color: var(---text);
}

.align-end {
  text-align: end;
}

.card-header-fields {
  font-weight: bold;
}

.mat-card {
  font-family: 'Volvo Novum SemiLight' !important;
}

.mat-card-header {
  font-family: 'Volvo Novum SemiLight' !important;
  font-weight: bold;
}

.mat-row:nth-child(2n) {
  background-color: var(---background);
}

.text-card {
  font-family: 'Volvo Novum SemiLight' !important;
  color: #5e6e82;
}

.flexbox-col {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.header-color {
  background-color: #c1c6d6;
  color: #fff;
}

.mat-card-header-text {
  margin: 0 8px !important;
}

.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
  font-family: 'Volvo Novum SemiLight' !important;
}

.mat-expansion-panel-header-title {
  font-family: 'Volvo Novum SemiLight' !important;
  font-weight: 500;
}

.mat-tooltip {
  font-family: 'Volvo Novum SemiLight' !important;
  background: rgba(97, 97, 97, 1);
}

.mat-header-cell {
  font-size: clamp(12px, 1vw, 14px);
  background-color: #dadada;
  word-wrap: normal;
  padding: 0 7px !important;
}

.mat-button,
.mat-stroked-button {
  color: #fff;
  background-color: var(---button);
}

.fit-country .mat-form-field-infix {
  width: 8vw !important;
  padding: 0.5em 0 0.3em 0 !important;
}

.mat-calendar-period-button {
  min-width: 0;
  color: inherit !important;
  background-color: transparent;
}

.no-table-data {
  margin: 0 0 0 30vw;
  height: 35px;
  border-radius: 20px;
  background-color: rgb(18, 81, 181);
  color: white;
  width: 25vw;
  font-size: 15px;
  font-family: 'Volvo Novum SemiLight' !important;
  text-align: center;
}

.close-button {
  position: absolute !important;
  top: 0;
  right: 0;
}

.flex-column {
  display: flex !important;
  flex-direction: column;
}

.flex-row {
  display: flex !important;
  flex-direction: row;
}

.selectedRow {
  background-color: whitesmoke !important;
  outline: 2px solid rgba(18, 81, 181, 0.8);
}

.mat-form-field {
  position: relative;
  padding-right: 15px;
  padding-left: 15px;
  font-family: 'Volvo Novum SemiLight' !important;
}

.mat-drawer-container {
  background-color: unset;
}

td.mat-cell {
  padding: 0 10px !important;
}

.text-center {
  text-align: center !important;
}

.mat-tab-group {
  align-items: center;
  font-family: 'Volvo Novum SemiLight' !important;
  margin-top: 1rem;
}

.mat-tab-label {
  font-family: 'Volvo Novum SemiLight' !important;
  font-weight: bold;
}

.mat-tab-body-wrapper {
  margin-top: 1rem;
  width: 100%;
}

a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}

/* Sticky footer styles
-------------------------------------------------- */

html {
  font-size: clamp(10px, 1vw, 14px);
  background-color: var(---background);
}

table {
  font-family: 'Volvo Novum SemiLight' !important;
  color: #464855;
  border-width: 5%;
  margin-top: 2%;
  width: 100%;
  white-space: normal;
}

table th {
  margin-bottom: 0.5rem;
  font-family: 'Volvo Novum SemiLight' !important;
  font-size: calc(0.11vw + 1.5vh);
  color: var(---text) !important;
  font-weight: bold;
}

tr.queue-element-row:not(.queue-expanded-row):hover {
  background: whitesmoke;
}

tr.queue-element-row:not(.queue-expanded-row):active {
  background: #efefef;
}

.queue-element-row {
  border-bottom-width: 0;
}

.queue-element-detail {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

tr.queue-detail-row {
  height: 0 !important;
}

.table-responsive {
  display: block;
  width: 100%;
}

.expanded-row-card {
  margin-bottom: 0;
  padding: 0 !important;
}

.mat-cell {
  font-size: clamp(11px, 1vw, 13px);
}

th.mat-header-cell:first-of-type,
td.mat-footer-cell:first-of-type {
  padding-left: 10px !important;
  padding-right: 0px !important;
}

td.mat-cell:first-of-type,
td.mat-cell:last-of-type {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

th.mat-sort-header-sorted {
  color: black;
}

th.mat-header-cell:last-of-type,
td.mat-footer-cell:last-of-type {
  padding-right: 10px !important;
}

.mat-button,
.mat-raised-button,
.mat-icon-button,
.mat-stroked-button,
.mat-flat-button,
.mat-fab,
.mat-mini-fab {
  font-family: 'Volvo Novum SemiLight' !important;
}

.mat-expansion-panel-header {
  font-family: 'Volvo Novum SemiLight' !important;
}

input {
  font-family: 'Volvo Novum SemiLight' !important;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  font-family: 'Volvo Novum SemiLight' !important;
}

.mat-stroked-button {
  padding-right: 2rem !important;
  padding-left: 2rem !important;
}

.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: rgb(20, 20, 20) !important;
}

.mat-dialog-actions {
  justify-content: space-between !important;
}

.click-cell {
  cursor: pointer;
  color: rgba(18, 81, 181, 0.8);
}

.mat-stroked-button.mat-button-disabled {
  background-color: rgba(18, 81, 181, 0.8);
}

.pagination {
  margin-left: 0.5%;
}

.page-item.active .page-link {
  z-index: 0 !important;
}

/*
@media (min-width: 768px) {
  html {
    font-size: calc(0.11vw + 1.5vh);
  }
}
*/
.border-top {
  border-top: 1px solid #e5e5e5;
}

.border-bottom {
  border-bottom: 1px solid #e5e5e5;
}

.mat-dialog {
  font-family: 'Volvo Novum SemiLight' !important;
  font-size: 1.1rem;
}

.box-shadow {
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.05);
}

button.accept-policy {
  font-size: 1rem;
  line-height: inherit;
}

/* Sticky footer styles
-------------------------------------------------- */

/* html {
  position: relative;
  min-height: 100%;
}

body {
  /* Margin bottom by footer height */
/* margin-bottom: 40px; */
/*} */

.footer {
  display: inline-flex;
  align-items: center;
  padding: 0%;
  bottom: 0;
  width: 100%;
  white-space: nowrap;
  height: 60px;
  line-height: 60px;
  font-family: 'Volvo Novum SemiLight' !important;
  font-weight: 400;
  background-color: #fff;
  color: #6c757d !important;
  z-index: 1;
}

input,
select,
file,
textarea {
  max-width: 400px;
}

.add-background {
  background-color: var(---background);
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: 0 auto 0 auto;
}

/* Change the normal behavior of navbar */

.brand-logo {
  width: 120px;
  height: 60px;
  display: block;
  margin-left: 20px;
  margin-right: 20px;
}

.dv-brand-title {
  margin-left: 24px;
  margin-right: 10px;
  margin-top: 0;
}

.main-header {
  position: fixed;
  width: 100%;
  height: 80px;
  box-shadow:
    0 1px 15px rgb(0 0 0 / 4%),
    0 1px 6px rgb(0 0 0 / 4%);
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  background: #fff;
  z-index: 100;
}

.logo img {
  width: 60px;
  height: 60px;
  margin: 0 auto;
  display: block;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: 'Volvo Novum SemiLight' !important;
  font-weight: 400;
  line-height: 1.2;
  margin-bottom: 0.5rem;
  color: #464855;
}

.bd-masthead {
  padding: 3rem 0;
  background: linear-gradient(165deg, #f7f5fb 50%, #fff 50%);
}

.avatar {
  vertical-align: middle;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.export {
  display: flex;
  justify-content: flex-end;
  text-align: center;
  font-family: 'Volvo Novum SemiLight' !important;
  color: #6c757d !important;
  font-weight: 500;
  margin: 0 3vw 3vh 0;
}

.profile-block {
  position: fixed;
  left: 80%;
  top: 1.5%;
}

.profile-block h5 {
  padding-top: 7%;
}

.profile-block img {
  margin-right: 6px;
}

.breadcrumb h1 {
  font-size: 1.5rem;
  line-height: 1;
  margin: 0;
}

.brand-title {
  display: inline;
  padding-left: 6px;
  font-weight: 400 !important;
  vertical-align: middle;
  color: var(---brand-title);
  font-family: 'Volvo Broad Pro Digital' !important;
}

.separator-breadcrumb {
  margin-bottom: 1rem;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.small-select {
  padding: 0 !important;
}

.row {
  display: flex;
  flex-wrap: wrap;
  /*
  margin-right: -15px;
  margin-left: -15px;
 */
  font-family: 'Volvo Novum SemiLight' !important;
}

.dialog-row {
  display: flex;
  flex-wrap: wrap;
  font-family: 'Volvo Novum SemiLight' !important;
  text-align: end;
  font-weight: 500;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.margin-dialog {
  margin: 0 !important;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.mat-dialog-content {
  max-height: unset !important;
  height: 20rem !important;
  display: grid !important;
}

.mat-select {
  font-family: 'Volvo Novum SemiLight' !important;
}

.dialog-col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  font-weight: 500;
  text-align: end;
}

.evenBackground {
  background-color: var(---background);
}

.mat-sort-header-container:not(.mat-sort-header-sorted) .mat-sort-header-arrow {
  opacity: 0.25 !important;
  transform: translateY(0px) !important;
}

.margin-dialog {
  margin-right: 8rem !important;
}

.dialog-header {
  border-bottom: 1px solid black;
  width: 100%;
  display: block;
  margin-bottom: 1rem;
}

.dialog-sub-header {
  margin-bottom: 1rem;
}

.dialog-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}

.better-tooltip {
  font-size: medium;
}

.form-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row > .col,
.form-row > [class*='col-'] {
  padding-right: 10px;
  padding-left: 10px;
}

.form-col {
  display: flex;
  flex-direction: column;
}

@media (min-width: 768px) {
}

.col-md-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.div-card-principal {
  padding: 1% 2% 3% 1.7%;
}

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem;
}

@media screen and (max-width: 1370px) {
  .card-body {
    flex: 1 1 auto;
    padding-top: 3rem;
    width: 100%;
  }
}

.form-group label {
  color: #1b406c;
  margin-bottom: 4px;
  /* font-family: 'Quicksand', Georgia, 'Times New Roman', Times, serif;
  font-weight: bold; */
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
  font-family: 'Volvo Novum SemiLight' !important;
  /* font-size: calc(0.7vw + 0.5vh)!important;*/
  color: #6c757d !important;
  font-weight: 500;
}

.search-right-block {
  margin-top: 1rem;
  min-width: 18vw;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.9695rem + 2px);
  padding: 0.375rem 0.75rem;
  /*font-size: calc(0.4vw + 0.7vh) !important;*/
  line-height: 1.5;
  color: #17365c;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

.btn-color-blue-volvo {
  color: #fff;
  background-color: #003473;
  border-color: #003473;
}

.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 0.9rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #001d40;
  border-color: #001733;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.btn-primary:hover,
.btn-outline-primary:hover {
  background: #003473;
  box-shadow: 0 8px 25px -8px #003473;
  border-color: #003473;
}

.btn-primary:hover {
  color: #fff;
  background-color: #00234d;
  border-color: #001d40;
}

.btn:hover,
.btn:focus {
  text-decoration: none;
}

/*Table layout*/

.tbl {
  padding-left: 2%;
  padding-right: 2%;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 0;
  margin-bottom: 0;
  list-style: none;
  background-color: transparent;
  border-radius: 0.25rem;
  font-size: 13px;
  margin-block-start: unset;
  margin-left: 0.5rem;
  color: rgb(20, 20, 20) !important;
}

.breadcrumb-item {
  display: flex;
}

.breadcrumb-padding {
  padding: 0 5rem;
}

.ativo {
  display: inline;
  padding: 0.2em 0.6em 0.3em;
  line-height: 1;
  color: #00864e !important;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
}

.greenStatus {
  color: #00864e;
  font-weight: 500;
}

.redStatus {
  color: #e6193f;
  font-weight: 500;
}

.yellowStatus {
  color: #f39c12;
  font-weight: 500;
}

.brownStatus {
  color: #c46632;
  font-weight: 500;
}

.cancelled {
  display: inline;
  padding: 0.2em 0.6em 0.3em;
  line-height: 1;
  color: #f39c12 !important;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
}

.blocked {
  display: inline;
  padding: 0.2em 0.6em 0.3em;
  line-height: 1;
  color: #e6193f !important;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
}

.expired {
  display: inline;
  padding: 0.2em 0.6em 0.3em;
  line-height: 1;
  color: #c46632 !important;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
}

.expirado {
  background-color: #dedede;
  color: #000000;
  display: inline;
  padding: 0.2em 0.6em 0.3em;
  font-size: 75%;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
}

.excluido {
  background-color: #504e4e;
  color: #ffffff;
  display: inline;
  padding: 0.2em 0.6em 0.3em;
  font-size: 75%;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
}

.credit {
  background-color: #ccf6e4;
  display: inline;
  padding: 0.2em 0.6em 0.3em;
  font-size: 75%;
  line-height: 1;
  color: #00864e;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
}

.debit {
  color: #932338;
  background-color: #fad7dd;
  display: inline;
  padding: 0.2em 0.6em 0.3em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
}

.debit-simbol {
  margin-right: -7%;
}

.multiselect-dropdown {
  width: 75% !important;
  border-radius: 0.25rem !important;
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

.multiselect-dropdown .dropdown-btn {
  display: block;
  border: 1px solid #ced4da !important;
  height: calc(1.9695rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 0.813rem;
  line-height: 1.5;
  color: #17365c;
  background-color: #fff;
  background-clip: padding-box;
}

.selected-item {
  border: 1px solid #235591 !important;
  margin-right: 4px !important;
  background: #235591 !important;
  padding: 0px 5px !important;
  color: #fff !important;
  border-radius: 2px !important;
  float: left !important;
  max-width: 400px !important;
}

.multiselect-item-checkbox input[type='checkbox']:focus + div:before,
.multiselect-item-checkbox input[type='checkbox']:hover + div:before {
  border-color: #235591 !important;
  background-color: #f2f2f2;
}

.multiselect-item-checkbox input[type='checkbox'] + div:before {
  color: #235591 !important;
  border: 2px solid #235591 !important;
}

.multiselect-item-checkbox input[type='checkbox'] + div:after {
  background-color: #235591 !important;
}

.multiselect-item-checkbox input[type='checkbox']:checked + div:before {
  background: #235591 !important;
}

.flexbox-row {
  display: flex;
  justify-content: space-between;
}

.flex-col {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.flexbox-input {
  display: flex;
  flex-wrap: wrap;
}

.box1 {
  flex-grow: 1;
}

.box2 {
  flex-grow: 2;
}

.box3 {
  flex-grow: 3;
}

.box4 {
  flex-grow: 4;
}

.flex-space {
  display: flex;
  justify-content: space-between;
}

.flex-right {
  display: flex;
  justify-content: right;
}

.flex-start-button {
  display: flex;
  flex-direction: row;
  -ms-flex-align: start;
  margin-top: 1rem;
}

.inline-space-check {
  margin-bottom: 0;
  padding: 0.5rem;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.col-detail {
  flex: 0 0 15%;
  max-width: 15%;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: rgba(0, 0, 0, 0.12);
}

.row-detail {
  margin: 0 !important;
  background-color: rgba(254, 236, 207, 0.5);
  background-color: #f8f9fa !important;
}

.left-align {
  margin-left: 0 !important;
}

.border-bottom {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: rgba(0, 0, 0, 0.12);
}

.card-secondary {
  padding: 1% 2% 0% 1.7% !important;
  margin-top: -5%;
  background-color: #f8f9fa !important;
}

.cursorPointer {
  cursor: pointer;
}

.center-button {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.center {
  justify-content: space-around;
  min-height: 12vh;
}

.space-around {
  justify-content: space-around;
}

.search-container {
  margin-left: 20px;
  margin-top: 15px;
  width: 95%;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;
  align-items: center;
}

.align-center {
  text-align: center;
}

.col-1 {
  -ms-flex: 0 0 8.333333%;
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}

.col-2 {
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.col-3 {
  -ms-flex: 0 0 23.5%;
  flex: 0 0 23.5%;
  max-width: 23.5%;
}

.col-4 {
  -ms-flex: 0 0 31.333333%;
  flex: 0 0 31.333333%;
  max-width: 31.333333%;
}

.col-5 {
  -ms-flex: 0 0 41.666667%;
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}

.col-6 {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  -ms-flex: 0 0 54%;
  flex: 0 0 54%;
  max-width: 54%;
}

.col-8 {
  -ms-flex: 0 0 66.666667%;
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

.col-9 {
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  -ms-flex: 0 0 83.333333%;
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

.col-11 {
  -ms-flex: 0 0 91.666667%;
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}

.col-12 {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

.breadcrumb-item {
  display: flex;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c757d;
  content: '/';
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}

.breadcrumb-item.active {
  color: #6c757d;
  font-weight: 500;
}

.align-items-center {
  -ms-flex-align: center !important;
  align-items: center !important;
}

.justify-content-center {
  -ms-flex-pack: center !important;
  justify-content: center !important;
}

@media screen and (max-width: 1280px) {
  .form-group label {
    font-size: unset !important;
  }

  .form-control {
    font-size: unset !important;
    min-width: 300px;
  }

  .fixed-top {
    position: static;
  }

  .flexbox-input {
    flex-wrap: nowrap;
  }

  app-invoice .mat-expansion-panel-body {
    padding: 0 !important;
  }

  app-invoice .form-row .mat-form-field-flex {
    width: 160px !important;
  }

  @media screen and (max-width: 1200px) {
    .flexbox-input {
      flex-wrap: wrap;
    }
  }
}

mat-expansion-panel {
  box-shadow: var(--box-shadow-volvo) !important;
}
